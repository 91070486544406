import React, { useContext } from 'react'
import { css } from '@emotion/core'

import { mq } from './styles/Layout.styles'
import Div from './Div/Div.styles'
import Body1 from './Body1'
import Body2 from './Body2'
import ds from '../theme'

import { SolutionContext } from './SolutionProvider'
import { imageSVG } from '../components/styles/InternalPage.styles'

const Solution = ({
  title,
  children,
  // icon: Icon,
  src,
  id
}) => {
  const [active, setIsActive] = useContext(SolutionContext)
  const isActive = active === id
  return (
    <div
      onClick={() => setIsActive(id)}
      style={{
        padding: 24,
        backgroundColor: '#fff',
        borderRadius: 16
      }}
      css={
        css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        )
      }
    >
      <Div
        flexDirection='row'
        alignItems='center'
      >
        <div
          style={{
            width: '68px',
            height: '68px',
            borderRadius: '50%',
            marginRight: ds.space(3),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
          css={imageSVG}
        >
          <img
            src={src}
            alt='Solution Icon'
          />
        </div>
        <Body1
          selected
          on='onSurface'
          variant='highEmphasis'
          style={{
            lineHeight: 1.77,
            letterSpacing: 0.5
          }}
        >
          {title}
        </Body1>
      </Div>
      {children && (
        <Div
          style={{
            position: 'relative',
            opacity: isActive ? 1 : 0,
            maxHeight: isActive ? '100%' : 0,
            transition: isActive ? 'max-height 2s, opacity 0.35s 0.35s' : 'max-height 0.3s 0.35s, z-index 0.7s 0.7s, opacity 0.7s',
            zIndex: isActive ? 1 : -1
          }}
        >
          <Body2
            color='black'
            variant='mediumEmphasis'
            mt={[3]}
          >
            {children}
          </Body2>
        </Div>
      )}
    </div>
  )
}

export default Solution
