import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const clipagemItem = css(
  mq({
    width: ['86%', 328],
    marginBottom: [16]
  })
)

export const IsoItemBps = css(
  mq({
    width: ['auto'],
    background: ['#7D10DD !important'],
    borderRadius: ['0px !important'],
    borderBottom: ['2px solid #9013fe']
  })
)

export const tabContentBps = css(
  mq({
    flexDirection: ['column']
  })
)

export const titleContent = css(
  mq({
    textAlign: ['left']
  })
)

export const imgBps = css(
  mq({
    // height: [183, 200, 315, 253],
    borderTopLeftRadius: [12],
    borderTopRightRadius: [12, 12, 0, 0],
    borderBottomLeftRadius: [0, 0, 12, 12]

  })
)

export const clipagemContentItem = css`
  margin-bottom: 30px;
  flex-direction: column;
  display: flex;
  div:not(:last-child) {
    margin-bottom: 30px;
  }
  h3 {
    font-size: 24px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: normal;
    color: #212121;
    margin-bottom: 16px
  }
  h4 {
    font-size: 18px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #878694;
    margin-bottom: 6px;
  }
  a {
    font-size: 16px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1;
    letter-spacing: 0.54px;
    color: #ff5800;
    :hover {
      text-decoration: underline;
    }
  }
  a:not(:last-child) {
    margin-bottom: 8px;
  }
`
export const tabItem = css`
  display: flex;
`

export const imgItem = css`
  img {
    display: flex;
    width: 100%;
    ${imgBps};
  }
`

export const linkStyle = css`
  align-items: center;
  display: flex;
  svg {
    margin-left: 8px;
  }
  :hover {
    text-decoration: underline;
  }
`

export const IsoItem = css`
  ${IsoItemBps};
  div:nth-of-type(1) {
    justify-content: center;
    font-size: 16px !important;
    font-weight: bold !important;
    font-stretch: normal !important;
    font-style: normal !important;
    line-height: 2.25 !important;
    letter-spacing: normal !important;
    color: #f9fafa !important;
    margin: 0 !important;
    align-items: center;
    display: flex;
    background: #7D10DD;
    span {
      font-weight: normal !important;
    }
    svg {
      margin-right: 10px;
    }
  }
`

export const CertificateContentItem = css`
  background: #7D10DD !important;
  width: 100%;
  max-width: 220px;
  display: flex;
  flex-direction: column;
  img {
    margin-top: 35px;
  }
  p {
    font-size: 14px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.71;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
    margin-bottom: 36px;
  }
`

export const tabItems = css`
  height: 100px;
  list-style: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: normal;
  color: #f9fafa;
  justify-content: center;
  align-items: center;
  display: flex;
  outline: none;
  span {
    font-weight: normal !important;
  }
  div:not(:last-child) {
    border-bottom: 2px solid #9013fe;
  }
`