import { css } from '@emotion/core'
import { mq } from './Layout.styles'

export const contetSliderBps = css(
  mq({
    marginLeft: [19, 30, 70, 70]
  })
)

export const H3Bps = css(
  mq({
    fontSize: [18, 20, 24, 24],
    lineHeight: [1.36, 1.31],
    // height: [74, 80, 95, 95],
    width: [270, 300, 400, 400]
  })
)

export const contetSlider = css`
  ${contetSliderBps};
  z-index: 999999;
  h3 {
    text-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
    ${H3Bps};
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    letter-spacing: normal;
    color: #ffffff;
  }
  span {
    font-size: 11.8px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.36;
    letter-spacing: 2px;
    color: #ff5800;
    text-transform: uppercase;
  }
  a:hover {
    text-decoration: underline;
    color: #ffffff;
  }
`

export const overlay = css`
  ::after {
    content: "";  // :before and :after both require content
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(90deg, rgba(0,0,0,1) 19%, rgba(0,0,0,0.3841911764705882) 66%);
    opacity: .7;
    border-radius: 12px;
  }
`