import React from 'react'
import { Tabs, TabContent } from 'react-tabs-redux'
import CertificatesMobile from '../components/CertificatesMobile'

const Tab2 = () => (
  <Tabs
    onChange={tab => console.log(`Tab selected: ${tab}`)} // eslint-disable-line no-console
  >
    <div>
      <TabContent>
        <CertificatesMobile />
      </TabContent>
    </div>
  </Tabs>
);

export default Tab2