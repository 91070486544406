import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'
import { generate } from 'shortid'

import ds from '../theme'

const styles = {
  dots: {
    position: 'relative',
    padding: '20px 0 28px',
    display: 'flex',
    justifyContent: 'center'
  },
  dotOuter: {
    width: 8,
    height: 8,
    padding: 4,
    position: 'absolute'
  },
  dot: {
    width: 8,
    height: 8,
    background: ds.brand('blueGrey'),
    transition: 'all 400ms cubic-bezier(0.4, 0.0, 0.2, 1)',
    borderRadius: 4
  }
}

class Dots extends Component {
  constructor (props) {
    super(props)
    this.state = {
      previousIndex: props.index || 0
    }
  }

  componentWillReceiveProps ({index}) {
    if (index !== this.props.index) {
      this.setState({previousIndex: this.props.index})
      this.timeout = setTimeout(() => {
        this.timeout = null
        this.setState({previousIndex: index})
      }, 450)
    }
  }

  componentWillUnmount () {
    if (this.timeout !== null) {
      clearTimeout(this.timeout)
    }
  }

  handleDotClick = (index, event) => {
    if (this.props.onDotClick !== null) {
      this.props.onDotClick(index, event)
    }
  }

  render () {
    const {classes, count, index, style = {}, onDotClick, mobile, ...other} = this.props
    const {previousIndex} = this.state
    let currRow = 0
    let leftPos = -1

    return (
      <div style={{...style, width: mobile ? '100%' : count * 16}} {...other}>
        <div className={classes.dots}>
          {Array(count)
            .fill(0)
            .map((item, i) => i)
            .map((i) => {
              if (i % 8 === 0) {
                currRow += 1
                leftPos = -1
              }
              leftPos += 1
              return (
                <div
                  key={generate()}
                  className={classes.dotOuter}
                  style={{
                    left: leftPos * 16,
                    top: mobile && currRow * 16,
                    cursor: onDotClick != null ? 'pointer' : 'inherit'
                  }}
                  onClick={(event) => this.handleDotClick(i, event)}
                >
                  <Paper
                    elevation={0}
                    className={classes.dot}
                    style={{
                      opacity: mobile
                        ? i === index ? 1 : 0.5
                        : i >= Math.min(previousIndex, index) && i <= Math.max(previousIndex, index) ? 0.5 : 0.5
                    }}
                  />
                </div>
              )
            }
          )}
          {!mobile && <Paper
            elevation={0}
            className={classes.dot}
            style={{
              position: 'absolute',
              marginTop: 4,
              left: Math.min(previousIndex, index) * 16 + 4,
              width: Math.abs(previousIndex - index) * 16 + 8
            }}
          />}
        </div>
      </div>
    )
  }
}

Dots.propTypes = {
  count: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  style: PropTypes.object,
  onDotClick: PropTypes.func,
  classes: PropTypes.object,
  mobile: PropTypes.bool
}

export default withStyles(styles)(Dots)