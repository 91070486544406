import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Link }  from 'gatsby'
import H6 from './H6'
import Modal from './PromotionModal'
import { btnFooter } from '../components/styles/ContainedButton.styles'
import { contentDesc } from '../components/styles/InternalPage.styles'

const useModalStyles = makeStyles(theme => ({
  paper: {
    backgroundColor: '#ff5800',
    padding: '50px 50px',
    borderRadius: 20,
    boxShadow: '-4px 10px 20px 0px rgba(0,0,0,0.12)',
  },
}))

const WannaBuyModal = ({
  visible,
  onClose
}) => {
  const [status, setStatus] = useState('default')
  const onCloseModal = () => {
    setStatus('default')
    onClose()
  }
  const classes = useModalStyles()
  const statuses = {
    default: (
      <div className={classes.paper}>
        <div
          style={{
            fontSize: 24,
            fontWeight: 600,
            lineHeight: 1.08,
            letterSpacing: 0.31,
            color: 'rgba(255, 255, 255, 0.8)',
            marginBottom: 16,
          }}
        >
          OFERTA!
        </div>
        <div
          style={{
            fontSize: 17,
            fontWeight: 600,
            fontStretch: 'normal',
            fontStyle: 'normal',
            lineHeight: 'normal',
            letterSpacing: 0.23,
            color: 'rgba(0,0,0,0.87)',
            backgroundColor: '#fff',
            marginBottom: 8,
            width: 223,
            padding: 5
          }}
        >
          Servidor dedicado a partir de: 
        </div>

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <div
            css={contentDesc}
            style={{
              marginBottom: 30
            }}
          >
            <div
              style={{
                textAlign: 'left'
              }}
            >
              <H6
                // mt={[2]}
                style={{
                  justifyContent: 'flex-start'
                }}
              >
                <span style={{ marginRight: 4 }}>R$ </span> 497<span>,00<b>/mês</b></span>
              </H6>
              <p style={{ fontSize: 11 }}>*Promoção válida enquanto durar o estoque.</p>
              <p style={{ fontWeight: 'bold', marginTop: 16 }}>Condições especiais com 3 meses de gerenciamento avançado grátis!</p>
            </div>
          </div>
          <div 
            css={contentDesc}
            style={{
              marginBottom: 30
            }}
          >
            <p><span>Faça parte do grupo de mais de 50 mil clientes satisfeitos em todo o mundo.</span></p>
          </div>
          <Link to="/oferta">
            <div
              css={btnFooter}
              style={{
                maxWidth: '210px',
                display: 'flex',
                marginBottom: 10,
                justifyContent: 'center'
              }}
              onClick={onCloseModal}
            >
              Garanta seu desconto agora!
            </div>
          </Link>
        </div>
      </div>
    )
  }

  return (
    <Modal
      open={visible}
      hasArrowBack={['needHelp', 'callForm', 'messageForm'].includes(status)}
      hideIcons={status === 'sent'}
      onClose={onCloseModal}
      onBack={() => setStatus('default')}
      style={{
        zIndex: 2000,
      }}
    >
      {statuses[status]}
    </Modal>
  )
}

export default WannaBuyModal
