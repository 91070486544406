import React,  { useState } from 'react'
import PropTypes from 'prop-types' 
import { graphql, Link }  from 'gatsby'
import MediaQuery from 'react-responsive'
import { css } from '@emotion/core'

import Img from '../components/AtomImg'
import Layout from '../components/Layout'
import Seo from '../components/Seo'
import Row from '../components/Row'
import Column from '../components/Column'
import Text from '../components/Text'
import ds from '../theme'
import Articles from '../components/ArticlesHome'
// import PromotionDay from '../components/PromotionDay'

// import { Container } from '../components/styles/Layout.styles'
import Carousel from '../components/Carousel'
import H4 from '../components/H4'
import Body2 from '../components/Body2'
import Div from '../components/Div/Div.styles'
import H6 from '../components/H6'
import ContainedButton from '../components/ContainedButton'
import OutlineButton from '../components/OutlinedButton'
import Hr from '../components/Hr'
import H2 from '../components/H2'
import H3 from '../components/H3'
import { mq } from '../components/styles/Layout.styles'
import { 
  h4Style,
  h2Style,
  item,
  imgHomeBox,
  linkCard,
  imageSVG,
  imgCarousel,
  IsoTitle,
  IsoSubtitle
} from '../components/styles/InternalPage.styles'
import { withSolutions } from '../components/SolutionProvider'
import SolucoesPersonalizadas from '../components/SolucoesPersonalizadas'
import TabCertificates from '../components/TabCertificates'
import CertificateMobile from '../components/IsoMobile'

import Parvi from '../images/clients/grupo-parvi.jpg'
import Aiam from '../images/clients/aiam.png'
import Basis from '../images/clients/basis.jpg'
import BateriasMoura from '../images/clients/baterias-moura.png'
import Bentonisa from '../images/clients/bentonisa.jpg'
import Brasimpex from '../images/clients/brasimpex .jpg'
import Britania from '../images/clients/britania.png'
import CamaraJp from '../images/clients/camara-jp.png'
import CartoriaAzevedoBastos from '../images/clients/cartorio-azevedo-bastos.png'
import Casatudo from '../images/clients/casatudo.png'
import Cbatech from '../images/clients/cbatech.png'
import ChilliBeans from '../images/clients/chilli-beans.png'
import CienciasMedicasPb from '../images/clients/ciencias-medicas-pb.png'
import ClubeTurismo from '../images/clients/clube-turismo.jpg'
import CoifeOdonto from '../images/clients/coife-odonto.png'
import ColengioAntares from '../images/clients/colegio-antares.jpg'
import Conductor from '../images/clients/conductor.png'
import Contreina from '../images/clients/contreina .jpg'
import DbDireito from '../images/clients/db-direto.jpg'
import DiretrixOn from '../images/clients/diretrix-on.jpg'
import Ecologica from '../images/clients/ecologica.png'
import EGen from '../images/clients/e-gen.jpg'
import EleveHost from '../images/clients/eleva-host.png'
import Elizabeth from '../images/clients/elizabeth.png'
import Equilibrio from '../images/clients/equilibrio.png'
import FaculdadeCescage from '../images/clients/faculdade-cescage.jpg'
import FaculdadeFutura from '../images/clients/faculdade-futura.jpg'
import FaculdadeSouza from '../images/clients/faculdade-souza.png'
import FaculdadeUnicampo from '../images/clients/faculdade-unicampo.jpg'
import Fan from '../images/clients/fan.png'
import Fps from '../images/clients/fps.png'
import GrupoAssesi from '../images/clients/grupo-assesi.jpg'
import GrupoHorizonteOriginal from '../images/clients/grupo-horizonte-original.png'
import HospitalMemorialSF from '../images/clients/hospital-memorial-sao-francisco.png'
import HospitalRegionalDeFranca from '../images/clients/hospital-regional-de-franca.jpg'
import Iland from '../images/clients/iland.png'
import InstitutoAdvogados from '../images/clients/instituto-advogados.png'
import Jotur from '../images/clients/jotur.jpg'
import K2Host from '../images/clients/k2host.png'
import KurierTec from '../images/clients/kurier-tecnologia.png'
import LinkSolution from '../images/clients/link-solutions.jpg'
import MarcosInacio from '../images/clients/marcos_inacio.png'
import Melita from '../images/clients/melita.png'
import MemorialDiagnostico from '../images/clients/memorial-diagnostico.png'
import NovaVidaTi from '../images/clients/nova-vida-ti.jpg'
import OAB from '../images/clients/oab.png'
import OABPe from '../images/clients/oab_pe.png'
import OmegaSistemas from '../images/clients/omega-sistemas.png'
import ReiAlimentos from '../images/clients/rei-alimentos.jpg'
import RogerioMenezesLeiloiro from '../images/clients/rogerio-menezes-leiloeiro.jpg'
import Roval from '../images/clients/roval.png'
import Sebrae from '../images/clients/sebrae.png'
import ServHost from '../images/clients/servhost.png'
import SescPb from '../images/clients/sesc-paraiba.png'
import Sintese from '../images/clients/sintese.png'
import SociedadeBrDeCbm from '../images/clients/socieade-brasileira-de-cbm.jpg'
import StaloDigital from '../images/clients/stalo-digital.jpg'
import SuporteOne from '../images/clients/suporte-one.jpg'
import TomoVale from '../images/clients/tomovale.jpg'
import Unifarma from '../images/clients/unifamma.png'
import Selfit from '../images/clients/selfit.jpg'
import Agemar from '../images/clients/agemar.png'
import DiasGomes from '../images/clients/dg-logo.jpg'
import EstaleiroAtlantico from '../images/clients/logo-sa.png'
import RedeMais from '../images/clients/rede-mais.png'
import Vsoft from '../images/clients/vsoft.jpg'
import Uniesp from '../images/clients/uniesp.png'
import Unifacisa from '../images/clients/unifacisa.jpg'
import Intelbras from '../images/clients/intelbras.jpg'
import UltimasNovidades from '../images/home/icons/ultimas_novidades.svg'
import PorqueHostdime from '../images/por-que-a-hostdime.svg'
import NossasInstalacoes from '../images/nossas-instalacoes.svg'
import PopUpImpostos from '../components/PopUpImpostos'
import Slider from '../components/Slider'
import Medic from '../images/clients/4Medic.png'
import CBMPB from '../images/clients/CBMPB.png'
import PMPB from '../images/clients/PMPB.png'
import RCContabilidade from '../images/clients/RC_Contabilidade.png'
import UniodontoRN from '../images/clients/UniodontoRN.png'
import ViaSul from '../images/clients/Via_Sul.png'
import Vortex from '../images/clients/Vortex.png'
import WiseDB from '../images/clients/WiseDB.png'
import Epasa from '../images/clients/epasa.png'
import MouraDubex from '../images/clients/moura-dubeux.png'
import Pedragon from '../images/clients/pedragon.png'
import FESP from '../images/clients/FESP_Faculdades.png'
import UNIMED from '../images/clients/unimed-jp.png'
import MoradaPaz from '../images/clients/moradadapaz.png'
import AVIL from '../images/clients/avil.png'
import Tropical from '../images/clients/logo-tropical.png'
import LogoSael from '../images/clients/sael.png'
import FeitosaNovais from '../images/clients/feitosa-e-novais.png'
import IrmaosVila from '../images/clients/irmaos_vila.png'
import Fir from '../images/clients/FIR.png'
// import CookieDisclaimer from '../components/CookieDisclaimer'
import OfferPopup from '../components/OfferPopup'

const initialModals = {
  promotionModal: false,
  offerModal: false
}

const clientsGrid = css(
  mq({
    display: 'grid',
    gridGap: '8px',
    gridTemplateColumns: ['repeat(2, 104px)', 'repeat(2, 104px)', 'repeat(4, 120px)'],
    gridTemplateRows: ['repeat(2, 104px)', 'repeat(2, 104px)', 'repeat(2, 120px)'],
    overflow: 'hidden'
  })
)

const CardItemPorque = ({
  icon: Icon,
  src,
  title,
  cta,
}) => (
  <Div
    css={item}
    flexDirection={['column', 'column', 'row']}
    alignItems={['flex-start', 'flex-start', 'center']}
    p={[16, 16, '54px 54px 52px', '63px 64px 62px']}
    style={{
      backgroundColor: '#fafafa',
      borderRadius: 16
    }}
  >
    <Div
      mb={[6, 6, 0]}
      mr={[0, 0, 4]}
      width={['auto']}
    >
      {Icon && <Icon />}
      {src && <img
        src={src}
        css={imgHomeBox}
      />}
    </Div>
    <div
      style={{
        maxWidth: 152
      }}
    >
      <H6
        selected
        on='onSurface'
        variant='mediumEmphasis'
        mb={[2]}
        fontSize={[19.6, 19.6, 23.7, 28]}
        style={{
          fontWeight: 500
        }}
      >
        {title}
      </H6>
      <Link
        to='/por-que-a-hostdime'
        css={linkCard}
      >
        {cta}
      </Link>
    </div>
  </Div>
)

const CardItemEstrutura = ({
  icon: Icon,
  src,
  title,
  cta,
}) => (
  <Div
    css={item}
    flexDirection={['column', 'column', 'row']}
    alignItems={['flex-start', 'flex-start', 'center']}
    p={[16, 16, '54px 54px 52px', '63px 64px 62px']}
    style={{
      backgroundColor: '#fafafa',
      borderRadius: 16
    }}
  >
    <Div
      mb={[6, 6, 0]}
      mr={[0, 0, 4]}
      width={['auto']}
    >
      {Icon && <Icon />}
      {src && <img
        src={src}
        css={imgHomeBox}
      />}
    </Div>
    <div
      style={{
        maxWidth: 152
      }}
    >
      <H6
        selected
        on='onSurface'
        variant='mediumEmphasis'
        mb={[2]}
        fontSize={[19.6, 19.6, 23.7, 28]}
        style={{
          fontWeight: 500
        }}
      >
        {title}
      </H6>
      <Link
        to='/estrutura'
        css={linkCard}
      >
        {cta}
      </Link>
    </div>
  </Div>
)

const clients = [
  {
    name: 'Irmãos Vila',
    logo: IrmaosVila
  },
  {
    name: 'FIR',
    logo: Fir
  },
  {
    name: 'Tropical Supermercados',
    logo: Tropical
  },
  {
    name: 'Sael',
    logo: LogoSael
  },
  {
    name: 'Feitosa e Novais Advogados Associados',
    logo: FeitosaNovais
  },
  {
    name: 'Unimed João Pessoa',
    logo: UNIMED
  },
  {
    name: 'Morada da Paz',
    logo: MoradaPaz
  },
  {
    name: 'Avil',
    logo: AVIL
  },
  {
    name: 'FESP Faculdades',
    logo: FESP
  },
  {
    name: 'Pedragon',
    logo: Pedragon
  },
  {
    name: 'Epasa',
    logo: Epasa
  },
  {
    name: 'Moura Dubex',
    logo: MouraDubex
  },
  {
    name: 'Unifacisa',
    logo: Medic
  },
  {
    name: 'CBMPB',
    logo: CBMPB
  },
  {
    name: 'PMPB',
    logo: PMPB
  },
  {
    name: 'RC Contabilidade',
    logo: RCContabilidade
  },
  {
    name: 'UniodontoRN',
    logo: UniodontoRN
  },
  {
    name: 'ViaSul',
    logo: ViaSul
  },
  {
    name: 'Vortex',
    logo: Vortex
  },
  {
    name: 'WiseDB',
    logo: WiseDB
  },
  {
    name: 'Unifacisa',
    logo: Unifacisa
  },
  {
    name: 'Intelbras',
    logo: Intelbras
  },
  {
    name: 'RedeMais',
    logo: RedeMais
  },
  {
    name: 'Vsoft',
    logo: Vsoft
  },
  {
    name: 'Uniesp',
    logo: Uniesp
  },
  {
    name: 'Dias Gomes',
    logo: DiasGomes
  },
  {
    name: 'Estaleiro Atlantico',
    logo: EstaleiroAtlantico
  },
  {
    name: 'Agemar',
    logo: Agemar
  },
  {
    name: 'Selfit',
    logo: Selfit
  },
  {
    name: 'Grupo Parvi',
    logo: Parvi
  },
  {
    name: 'Aiam',
    logo: Aiam
  },
  {
    name: 'Basis',
    logo: Basis
  },
  {
    name: 'Baterias Moura',
    logo: BateriasMoura
  },
  {
    name: 'Bentonisa',
    logo: Bentonisa
  },
  {
    name: 'Brasimpex',
    logo: Brasimpex
  },
  {
    name: 'Britania',
    logo: Britania
  },
  {
    name: 'Camara Jp',
    logo: CamaraJp
  },
  {
    name: 'Cartoria Azevedo Bastos',
    logo: CartoriaAzevedoBastos
  },
  {
    name: 'Casatudo',
    logo: Casatudo
  },
  {
    name: 'Cbatech',
    logo: Cbatech
  },
  {
    name: 'ChilliBeans',
    logo: ChilliBeans
  },
  {
    name: 'Ciencias Medicas Pb',
    logo: CienciasMedicasPb
  },
  {
    name: 'ClubeTurismo',
    logo: ClubeTurismo
  },
  {
    name: 'CoifeOdonto',
    logo: CoifeOdonto
  },
  {
    name: 'ColengioAntares',
    logo: ColengioAntares
  },
  {
    name: 'Conductor',
    logo: Conductor
  },
  {
    name: 'Contreina',
    logo: Contreina
  },
  {
    name: 'DbDireito',
    logo: DbDireito
  },
  {
    name: 'DiretrixOn',
    logo: DiretrixOn
  },
  {
    name: 'Ecologica',
    logo: Ecologica
  },
  {
    name: 'EGen',
    logo: EGen
  },
  {
    name: 'EleveHost',
    logo: EleveHost
  },
  {
    name: 'Elizabeth',
    logo: Elizabeth
  },
  {
    name: 'Equilibrio',
    logo: Equilibrio
  },
  {
    name: 'Faculdade Cescage',
    logo: FaculdadeCescage
  },
  {
    name: 'Faculdade Futura',
    logo: FaculdadeFutura
  },
  {
    name: 'Faculdade Souza',
    logo: FaculdadeSouza
  },
  {
    name: 'Faculdade Unicampo',
    logo: FaculdadeUnicampo
  },
  {
    name: 'Fan',
    logo: Fan
  },
  {
    name: 'Fps',
    logo: Fps
  },
  {
    name: 'Grupo Assesi',
    logo: GrupoAssesi
  },
  {
    name: 'Grupo Horizonte Original',
    logo: GrupoHorizonteOriginal
  },
  {
    name: 'Hospital Memorial SF',
    logo: HospitalMemorialSF
  },
  {
    name: 'Hospital Regional de Franca',
    logo: HospitalRegionalDeFranca
  },
  {
    name: 'Iland',
    logo: Iland
  },
  {
    name: 'Instituto Advogados',
    logo: InstitutoAdvogados
  },
  {
    name: 'Jotur',
    logo: Jotur
  },
  {
    name: 'K2Host',
    logo: K2Host
  },
  {
    name: 'KurierTec',
    logo: KurierTec
  },
  {
    name: 'Link Solution',
    logo: LinkSolution
  },
  {
    name: 'MarcosInacio',
    logo: MarcosInacio
  },
  {
    name: 'Melita',
    logo: Melita
  },
  {
    name: 'Memorial Diagnostico',
    logo: MemorialDiagnostico
  },
  {
    name: 'Nova Vida Ti',
    logo: NovaVidaTi
  },
  {
    name: 'OAB',
    logo: OAB
  },
  {
    name: 'OAB Pe',
    logo: OABPe
  },
  {
    name: 'Omega Sistemas',
    logo: OmegaSistemas
  },
  {
    name: 'Rei Alimentos',
    logo: ReiAlimentos
  },
  {
    name: 'Rogerio Menezes Leiloiro',
    logo: RogerioMenezesLeiloiro
  },
  {
    name: 'Roval',
    logo: Roval
  },
  {
    name: 'Sebrae',
    logo: Sebrae
  },
  {
    name: 'ServHost',
    logo: ServHost
  },
  {
    name: 'SescPb',
    logo: SescPb
  },
  {
    name: 'Sintese',
    logo: Sintese
  },
  {
    name: 'Sociedade Br de Cbm',
    logo: SociedadeBrDeCbm
  },
  {
    name: 'Stalo',
    logo: StaloDigital
  },
  {
    name: 'SuporteOne',
    logo: SuporteOne
  },
  {
    name: 'TomoVale',
    logo: TomoVale
  },
  {
    name: 'Unifarma',
    logo: Unifarma
  }
]

export const PageTemplate = ({
  data
}) => {
  console.log(data)
  // console.log('env', process.env.GATSBY_SERVER_ENV)
  const posts = data.allWordpressPost.edges
  const [modals, setModals] = useState(initialModals)

  const setModalVisibility = modal => visible => {
    setModals({
      ...modals,
      [modal]: visible
    })
  }
  return (
    <section>
      <Div
        px={[16, 16, 24, 24]}
        alignItems={['center']}
      >
        <Row
          flexDirection={['column', 'row']}
          mb={[0]}
          mt={[30, 60]}
          mx={[8, 8, 0, 0]}
          alignItems={['center']}
        >
          <Div
            maxWidth={[379, 568, 855, 1086]}
          >
            <Slider />
          </Div>
        </Row>
        {false && <Row
          flexDirection={['column', 'row']}
          mb={[0]}
          mt={[30, 60]}
          mx={[8, 8, 0, 0]}
          alignItems={['center']}
        >
          <PopUpImpostos />
        </Row>}
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[50, 80]}
            mt={[30, 60]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 400, 450]}
              >
                <Img
                  fluid={data.image1.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center', 'center', 'flex-start']}
                textAlign={['center', 'center', 'left']}
              >
                <H4
                  color='black'
                  variant='highEmphasis'
                  mb={[5, 5, 3]}
                  css={h4Style}
                >
                  Soluções globais<br />e personalizadas de<br />Data Center
                </H4>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[3]}
                  maxWidth={['323px', 328, 433, 433]}
                >
                  <Img
                    fluid={data.image1.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                {false && <Text
                  fontSize={[19]}
                  lineHeight={['32px']}
                  order={[1, 1, 2]}
                  style={{
                    fontWeight: '600',
                    letterSpacing: '0.24px',
                    color: '#212121'
                  }}
                  mb={[3, 3, 0]}
                >
                  Presente em 8 países<br /> Mais de 15 anos de experiência<br /> Mais de 50 mil clientes
                </Text>}
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  mb={[5]}
                  maxWidth={['100%', '100%', 400, 400]}
                >
                  Somos um dos melhores data center do Brasil e também somos globais. Somos parceria, estrutura, inovação e disponibilidade para o seu negócio. Conheça nossas soluções e entre em contato para personalizá-las às suas necessidades.
                </Body2>
                <ContainedButton
                  type='btnContainedLarge'
                  Link='/nossos-produtos'
                >
                  nossos produtos
                </ContainedButton>
              </Div>
            </Column>
          </Row>
        </Div>
        <Div
          alignItems={['center']}
          style={{
            backgroundColor: '#9013FE',
            marginBottom: 80
          }}
          mx={[-16, -16, -24, -24]}
          width={[
            'calc(100% + 32px)',
            'calc(100% + 32px)',
            'calc(100% + 48px)'
          ]}
        >
          <Div
            maxWidth={['100%', 390, 855, 1046]}
            alignItems={['center']}
          >
            <Row
              // pt={[40, 70]}
              // pb={[40, 70]}
              mx={[0, -16, -24, -24]}
              flexDirection={['column', 'column', 'column', 'row']}
            >
              <Column
                width={[1]}
                maxWidth={['100%', 395]}
                display={['flex']}
                justifyContent={['center']}
                flexDirection={['column']}
                mr={[0, 0, 0, 70]}
              >
                <H2 
                  css={IsoTitle}
                >
                  Nossos<br /> Certificados
                </H2>
                <div css={IsoSubtitle}>
                  É essencial entender se o seu Data Center cumpre não só os padrões ideais de estrutura física.
                </div>
                <Div
                  display={['none', 'none', 'none', 'block']}
                >
                  <ContainedButton
                    type='btnIso'
                    Link='/premiacoes-e-certificados'
                  >
                    ver todos certificados
                  </ContainedButton>
                </Div>
              </Column>
              <Column
                width={[1]}
                px={[0]}
                display={['none', 'none', 'none', 'block']}
              >
                <TabCertificates />
              </Column>
              <Column
                width={[1]}
                px={[0]}
                display={['block', 'block', 'block', 'none']}
              >
                <CertificateMobile />
                <div style={{ marginTop: 50, marginBottom: 70, textAlign: 'center' }}>
                  <ContainedButton
                    type='btnIso'
                    Link='/premiacoes-e-certificados'
                  >
                    ver todos certificados
                  </ContainedButton>
                </div>
              </Column>
            </Row>
          </Div>
        </Div>
        <Row
          mb={[50, 50, 100]}
          mx={[-16, -16, 0, 0]}
          maxWidth={['100%', 328, 1046]}
          style={{
            width: '100%'
          }}
        >
          <Column
            width={[1/2]}
          >
            <Link
              to='/por-que-a-hostdime'
              style={{
                textDecoration: 'none'
              }}
            >
              <CardItemPorque
                src={PorqueHostdime}
                title='Por que a HostDime?'
                cta='Saiba aqui'
              />
            </Link>
          </Column>
          <Column
            width={[1/2]}
          >
            <Link
              to='/estrutura'
              style={{
                textDecoration: 'none'
              }}
            >
              <CardItemEstrutura
                src={NossasInstalacoes}
                title='Nossas instalações'
                cta='Conheça'
              />
            </Link>
          </Column>
        </Row>
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[50, 50, 88, 100]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              order={[0, 0, 1, 1]}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 400, 450]}
              >
                <Img
                  fluid={data.image2.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Cloud Server<br />HostDime
                </H3>
                {/* {false && <Div
                  css={[
                    (css`
                    animation: ${cloudAni} 10s 5s infinite linear,
                  `),
                  cloud
                  ]}
                />} */}
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  maxWidth={['323px', 416]}
                >
                  <Img
                    fluid={data.image2.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  mb={[5]}
                >
                  Servidores em nuvem flexíveis, que ajustam automaticamente a CPU, RAM e o espaço de disco sob demanda, apresentando alta disponibilidade e failover automático. Aproveite nosso hardware de ponta, incluindo processadores de nível empresarial e os melhores SSDs da indústria para criar sua própria estrutura instantânea de negócios.
                </Body2>
                <ContainedButton
                  type='btnContainedLarge'
                  Link='/cloud-server'
                >
                  Explorar opções de Nuvem
                </ContainedButton>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          display={['block', 'block', 'none', 'none']}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[50, 50, 88, 100]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 400, 450]}
              >
                <Img
                  fluid={data.image3.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Servidores<br />Dedicados
                </H3>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  alignItems={['center']}
                  maxWidth={['323px', 361, 469]}
                >
                  <Img
                    fluid={data.image3.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  align='center'
                  mb={[5]}
                >
                  Hardwares fisicamente isolados, de uso exclusivo da sua empresa, trazendo assim, maior segurança e desempenho, além de contar com monitoramento proativo 24x7x365.
                </Body2>
                <OutlineButton
                  type='btnOutlinedLarge'
                  Link='/servidores-dedicados'
                >
                  configurações personalizadas
                </OutlineButton>
              </Div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          display={['block', 'block', 'none', 'none']}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 855, 1086]}
        >
          <Row
            flexDirection={['column', 'row']}
            mb={[50, 70]}
            mx={[-16, -16, 0, 0]}
            alignItems={['center']}
          >
            <Column
              width={[1, 1, 1, 1/2]}
              display={['none', 'none', 'flex']}
              order={[0, 0, 1, 1]}
              style={{
                justifyContent: 'center'
              }}
            >
              <Div
                maxWidth={['100%', '100%', 400, 450]}
              >
                <Img
                  fluid={data.image4.childImageSharp.fluid}
                  loading='eager'
                />
              </Div>
            </Column>
            <Column
              width={[1, 1, 5/6, 1/2]}
            >
              <Div
                alignItems={['center']}
                textAlign={['center']}
              >
                <H3
                  color='black'
                  variant='highEmphasis'
                  mb={[3]}
                  css={h2Style}
                >
                  Colocation<br />HostDime
                </H3>
                <Div
                  display={['block', 'block', 'none']}
                  mb={[5]}
                  alignItems={['center']}
                  maxWidth={['323px', '323px', 348, 50]}
                >
                  <Img
                    fluid={data.image4.childImageSharp.fluid}
                    loading='eager'
                  />
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  mb={[5]}
                >
                  Abrigue seu parque de ativos de TI em nossas estruturas certificadas e garanta a continuidade do seu negócio.
                </Body2>
                <OutlineButton
                  type='btnOutlinedLarge'
                  Link='/colocation'
                >
                  Saiba mais
                </OutlineButton>
              </Div> 
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0']}
          maxWidth={[328, 422 ,856 ,1023]}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 680]}
        >
          <Row
            pt={[50, 70]}
            pb={[50, 70]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
            >
              <Div
                alignItems={['center']}
              >
                <H4
                  color='black'
                  variant='highEmphasis'
                  align='center'
                  mb={[5]}
                >
                  Temos mais de 50 mil clientes<br /> em todo o mundo
                </H4>
                <Div
                  mb={[4]}
                  maxWidth={[220, 220, 510]}
                >
                  <MediaQuery minDeviceWidth={840}>
                    {/* You can also use a function (render prop) as a child */}
                    {(matches) => {
                      const maxItems = matches ? 8 : 4
                      const pages = Math.ceil(clients.length / maxItems)
                      return (
                        <Carousel
                          open
                          mobileDots={!matches}
                        >
                          {Array(pages).fill(0).map((item, i) => i).map((page, i) => {
                            const min = (i * maxItems)
                            const max = (i * maxItems) + maxItems
                            return (
                              <Div
                                key={`carouselpage-${i}`}
                                css={clientsGrid}
                              >
                                {clients.slice(min, max).map((client, i) => (
                                  <>
                                    {client.logo
                                      ? (
                                        <div
                                          key={`carouselitem-${i}`}
                                          style={{
                                            width: '100%',
                                            height: '100%'
                                          }}
                                        >
                                          <img
                                            src={client.logo}
                                            alt={client.name}
                                            css={imgCarousel}
                                            style={{
                                              objectFit: 'cover',
                                              lineHeight: 1
                                            }}
                                          />
                                        </div>
                                      )
                                      : (
                                        <div
                                          key={`carouselitem-${i}`}
                                          style={{
                                            height: '100%',
                                            backgroundColor: ds.brand('iceBlue')
                                          }}
                                        >
                                          {i + 1}
                                        </div>
                                      )
                                    }
                                  </>
                                ))}
                              </Div>
                            )
                          })}
                        </Carousel>
                      )}
                    }
                  </MediaQuery>
                </Div>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  align='center'
                  mb={[5]}
                >
                  Organizações públicas e privadas, de todos os<br /> segmentos, confiam na HostDime.
                </Body2>
                <OutlineButton
                  type='btnOutlinedLarge'
                  Link='/clientes'
                >
                  veja quem são nossos clientes
                </OutlineButton>
              </Div>
            </Column>
          </Row>
        </Div>
        <Div
          alignItems={['center']}
          style={{
            backgroundColor: ds.brand('iceBlue')
          }}
          mx={[-16, -16, -24, -24]}
          width={[
            'calc(100% + 32px)',
            'calc(100% + 32px)',
            'calc(100% + 48px)'
          ]}
        >
          <Div
            maxWidth={['100%', 390, 680]}
          >
            <Row
              pt={[40, 70]}
              pb={[40, 70]}
              mx={[0, -16, -24, -24]}
            >
              <Column
                width={[1]}
              >
                <div>
                  <H4
                    color='black'
                    variant='highEmphasis'
                    align='center'
                    mb={[3]}
                  >
                    Soluções Personalizadas
                  </H4>
                  <Body2
                    selected
                    on='onSurface'
                    variant='mediumEmphasis'
                    align='center'
                    mb={[5]}
                  >
                    Nossa equipe de desenvolvimento de negócios escuta você, entende a sua estrutura e molda serviços sob medida para otimizar o seu setor de TI. O nosso maior objetivo é garantir a segurança de dados críticos para a sua empresa e soluções para permitir mais inovação no setor. Somos personalizados para sanar a sua dor.
                  </Body2>
                  <SolucoesPersonalizadas />
                </div>
              </Column>
            </Row>
          </Div>
        </Div>
        <Div
          maxWidth={['100%', 390, 680]}
        >
          <Row
            pt={[50, 70]}
            pb={[50, 70]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
            >
              <div>
                <H4
                  color='black'
                  variant='highEmphasis'
                  align='center'
                  mb={[4]}
                >
                  Nós queremos transformar <br />
                  a sua empresa
                </H4>
                <Body2
                  selected
                  on='onSurface'
                  variant='mediumEmphasis'
                  align='center'
                  mb={[4]}
                >
                  Fale com um de nossos consultores e receba uma proposta personalizada de serviços para solucionar seus problemas com TI e otimizar os custos do seu negócio.
                </Body2>
                <Div
                  alignItems={['center']}
                >
                  <ContainedButton
                    type='btnContainedLarge'
                    Link='/voupranuvem'
                  >
                    Fale com nossos consultores
                  </ContainedButton>
                </Div>
              </div>
            </Column>
          </Row>
        </Div>
        <Hr
          lineColor='lightPeriwinkle'
          m={['0 0 40px']}
          maxWidth={[328, 422 ,856 ,1023]}
          style={{
            opacity: 0.5
          }}
        />
        <Div
          maxWidth={['100%', 390, 680]}
          alignItems={['center', 'center', 'stretch']}
        >
          <Row
            pb={[50, 70]}
            mx={[-16, -16, -24, -24]}
          >
            <Column
              width={[1]}
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center'
              }}
            >
              <Div
                flexDirection='row'
                alignItems='center'
                justifyContent='center'
                maxWidth={['100%', 344, '100%']}
                css={imageSVG}
              >
                <img
                  src={UltimasNovidades} 
                  style={{
                    marginRight: ds.space(3)
                  }}
                />
                <H4
                  selected
                  on='onSurface'
                  variant='highEmphasis'
                >
                  Últimas Novidades
                </H4>
              </Div>
              {posts.length > 0
                && Array(Math.ceil(posts.length / 2)).slice(0, 2).fill(0).map((item, i) => {
                  return (
                    <Row
                      key={`post-row-${i}`}
                      flexDirection={['column', 'column', 'row']}
                      mt={[30]}
                      
                      maxWidth={['100%', 344, '100%']}
                      width={['100%']}
                    >
                      {posts.slice(i === 0 ? 0 : i * 2, 2 + i * 2).map((post, x) => (
                        <Column
                          key={`post-item-${x + i}`}
                          width={[1, 1, 1/2]}
                          mb={[24, 24, 0]}
                        >
                          <Articles
                            title={post.node.title}
                            slug={post.node.slug}
                            meta={{
                              date: post.node.date,
                              duration: '6 min'
                            }}
                            img={post.node.featured_media && post.node.featured_media.localFile.publicURL}
                          />
                          <Hr
                            lineColor='lightPeriwinkle'
                            maxWidth={[50]}
                            style={{
                              marginLeft: 0
                            }}
                          />
                        </Column>
                      ))}
                    </Row>
                  )
              })}
              <div 
                style={{
                  marginTop: 50
                }}
              />
              <OutlineButton
                type='btnOutlinedMedium'
                Link='/blog'
              >
                Ver Mais Artigos
              </OutlineButton>
            </Column>
          </Row>
        </Div>
      </Div>
      {/* <CookieDisclaimer /> */}
      {false && <OfferPopup
        visible={modals.offerModal}
        onClose={() => setModalVisibility('offerModal')(false)}
      />}
    </section>
  )
}

PageTemplate.propTypes = {
  data: PropTypes.object
}

const StaticPage = ({ data }) => {
  return (
    <Layout>
      <Seo 
        title='Soluções globais e personalizadas de Data Center' 
        description='Somos parceria, estrutura, inovação e disponibilidade para o seu negócio. Conheça nossas soluções e cases de sucesso.' 
      />
      <PageTemplate
        title='Soluções globais e personalizadas de Data Center'
        data={data}
      />
    </Layout>
  )
}

StaticPage.propTypes = {
  data: PropTypes.object
}

export const query = graphql`
  fragment PostListFields on wordpress__POST {
    id
    slug
    content
    date(formatString: "DD MM YYYY")
    title
    featured_media {
      localFile {
        publicURL
      }
    }
  }
  query HomeQuery {
    allWordpressPost(
      sort: { fields: date, order: DESC }
      limit: 4
    ) {
      edges {
        node {
          ...PostListFields
        }
      }
    }
    image1: file(relativePath: { eq: "home/home-solucoes-globais.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
    image2: file(relativePath: { eq: "home/home-cloud-server.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
    image3: file(relativePath: { eq: "home/home-servidores-dedicados.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
    image4: file(relativePath: { eq: "home/home-colocation-hostdime.png" }) {
      childImageSharp {
        fluid{
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

export default withSolutions(StaticPage)
