import React from 'react'
import { Tabs, TabList, Tab, TabPanel } from 'react-tabs'
import IcoLock from '@material-ui/icons/Lock'
import IcoAccountBalance from '@material-ui/icons/AccountBalance'
import IcoAssignmentInd from '@material-ui/icons/VerifiedUser'
import IcoAssignment from '@material-ui/icons/Assignment'
import IcoCloudDone from '@material-ui/icons/CloudDone'
import IcoStars from '@material-ui/icons/Stars'
import Iso27001 from '../images/certificates/ISO-27001.png'
import Iso20000 from '../images/certificates/ISO-20000.png'
import Iso27701 from '../images/certificates/ISO-27701.png'
import Iso27017 from '../images/certificates/ISO-27017.png'
import Iso27018 from '../images/certificates/ISO-27018.png'
import Iso9001 from '../images/certificates/ISO-9001.png'

import { tabItems } from '../components/styles/Clipagem.styles'

const CustomTab = (props) => {
  const {selected} = props
  return (
    <Tab
      className={selected ? "react-tabs__tab--selected" : ''}
    >
      <div 
        onMouseEnter={props.onHover}
        css={tabItems}
      >
        {props.children}
      </div>
    </Tab>
  );
};
CustomTab.tabsRole = 'Tab';
const App = () => {
  const [tabIndex, setTabIndex] = React.useState(0)
  return (
    <div className="App">
      <Tabs selectedIndex={tabIndex} onSelect={tab => setTabIndex(tab)}>
        <TabList>
          <CustomTab key={0} onHover={() => {setTabIndex(0)}} >
            <IcoLock 
              style={{
                color: '#fff',
                marginRight: 10,
                width: 24
              }}
            />
            <p>ISO 27001<span>:2013</span></p>
          </CustomTab>
          <CustomTab key={1} onHover={() => {setTabIndex(1)}} >
            <IcoAccountBalance 
              style={{
                color: '#fff',
                marginRight: 10,
                width: 24
              }}
            />
            <p>ISO 20000<span>:2018</span></p>
          </CustomTab>
          <CustomTab key={2} onHover={() => {setTabIndex(2)}} >
            <IcoAssignmentInd 
              style={{
                color: '#fff',
                marginRight: 10,
                width: 24
              }}
            />
            <p>ISO 27701<span>:2019</span></p>
          </CustomTab>
          <CustomTab key={3} onHover={() => {setTabIndex(3)}} >
            <IcoAssignment 
              style={{
                color: '#fff',
                marginRight: 10,
                width: 24
              }}
            />
            <p>ISO 27017<span>:2015</span></p>
          </CustomTab>
          <CustomTab key={4} onHover={() => {setTabIndex(4)}} >
            <IcoCloudDone 
              style={{
                color: '#fff',
                marginRight: 10,
                width: 24
              }}
            />
            <p>ISO 27018<span>:2019</span></p>
          </CustomTab>
          <CustomTab key={5} onHover={() => {setTabIndex(5)}} >
            <IcoStars 
              style={{
                color: '#fff',
                marginRight: 10,
                width: 24
              }}
            />
            <p>ISO 9001<span>:2015</span></p>
          </CustomTab>
        </TabList>
        <TabPanel>
          <div className="panel-content">
            <img src={Iso27001} style={{ width: 120, marginBottom: 40 }} />
            <h2>A ISO 27001 certificou que a HostDime Brasil possui um modelo adequado de implementação, operação, monitoramento, revisão e gestão para Sistemas de Gestão de Segurança da Informação. É essencial para comprovar que o seu Data Center cumpre além dos altos padrões de infraestrutura.</h2>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <img src={Iso20000} style={{ width: 120, marginBottom: 40 }} />
            <h2>Com a certificação ISO 20000, a HostDime Brasil comprova que cumpre com o mais alto padrão de soluções e parceiros contratados, para atender às melhores práticas de manutenção, melhoria e inovação dos serviços prestados para você.</h2>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <img src={Iso27701} style={{ width: 120, marginBottom: 40 }} />
            <h2>Somos a primeira empresa brasileira a conquistar essa certificação, que comprova nosso alto padrão de Segurança da Informação. A ISO 27701 garante a melhoria contínua na gestão da privacidade dos dados pessoais, por isso, também age em conformidade com a LGPD (Lei Geral da Proteção de Dados do Brasil) e GDPR (General Data Protection Regulation da Europa).</h2>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <img src={Iso27017} style={{ width: 120, marginBottom: 40 }} />
            <h2>Com a norma ISO 27017,  a HostDime Brasil é certificada como uma empresa que promove um rigoroso controle de segurança para Cloud Computing, reduzindo os riscos de problemas de segurança de informação para ambientes de nuvem aos níveis mais baixos.</h2>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <img src={Iso27018} style={{ width: 120, marginBottom: 40 }} />
            <h2>Somos certificados no primeiro padrão internacional que estabelece um conjunto de boas práticas e controles de privacidade dos dados pessoais, voltado especificamente para serviços de computação em Nuvem Pública (Cloud Services Provider).</h2>
          </div>
        </TabPanel>
        <TabPanel>
          <div className="panel-content">
            <img src={Iso9001} style={{ width: 120, marginBottom: 40 }} />
            <h2>Conquistamos a ISO 9001, que certifica que a empresa dispõe de um sistema de alto padrão na gestão da qualidade focado na satisfação total dos clientes HostDime Brasil, que contam com uma entrega de produtos e serviços de forma consistente.</h2>
          </div>
        </TabPanel>
      </Tabs>
    </div>
)};
export default App