import React, { Component } from 'react'
import { Link }  from 'gatsby'
import ReactTextCollapse from 'react-text-collapse' 
import { css } from '@emotion/core'
import Div from '../components/Div/Div.styles'
import ds from '../theme'
import { mq } from '../components/styles/Layout.styles'
import H6 from '../components/H6'
import Paragraph from '../components/Paragraph'
import ServicosFinanceiros from '../images/servicos-financeiros.png'

import { scrollAction } from '../components/styles/InternalPage.styles'
import Column from './Column'

const TEXT_COLLAPSE_OPTIONS = { 
  collapse: false, 
  collapseText: '+ Entenda melhor e migre para o pagamento em real.', 
  expandText: '- Ocultar detalhes', 
  minHeight: 0, 
  maxHeight: 200,
  marginTop: 54, 
  overflow: 'hidden !important',
  textStyle: { 
    color: '#ff5800', 
    fontSize: '14px' ,
    lineHeight: 1.71,
    marginTop: '0px',
    textAlign: 'left',
    width: '100%',
    cursor: 'pointer'
  }
}

const Awards = ({
  licenceName,
}) => {
  return (
    <Div
      style={{
        padding: '30px',
        backgroundColor: '#f9fafa',
        borderRadius: 12,
      }}
      css={
        [css(
          mq({
            '&:not(:last-child)': {
              marginBottom: ds.space(3)
            } 
          })
        ),
        // item 
      ]
      }
    >
      <Div
        flexDirection={['column', 'row']}
        alignItems='flex-start'
        maxWidth={['100%', '100%', 856, 1023]}
      >
        <Column
          display={['none', 'none', 'flex']}
        >
          <img 
            src={ServicosFinanceiros} 
            alt=''
            style={{
              width: 100,
              height: 100
            }} 
            />
        </Column>
        <Column>
          <H6
            selected
            on='onSurface'
            variant='highEmphasis'
            style={{
              letterSpacing: 0.25,
              marginBottom: 16,
              textAlign: 'left',
              color: 'rgba(0, 0, 0, 0.6)',
              whiteSpace: 'pre-wrap',
              fontWeight: 600
            }}
          >
            {licenceName}
          </H6>
          <ReactTextCollapse
            options={TEXT_COLLAPSE_OPTIONS}
            css={scrollAction}
          >
            <Paragraph
              style={{
                fontSize: 14,
                lineHeight: 1.71,
                textAlign: 'left',
                color: 'rgba(0, 0, 0, 0.6)',
                marginBottom: 16
              }}
            >
              A Receita Federal do Brasil já <a href='http://normas.receita.fazenda.gov.br/sijut2consulta/link.action?idAto=55186'>oficializou no Diário Oficial da União</a> desde o dia 18 de agosto de 2014 que o pagamento por serviços de data center contratados em empresas estrangeiras deve incluir IRFF (Imposto de Renda Retido na Fonte), PIS/Pasep - importação, Cofins-importação e Cide - por se tratar de uma importação de serviços. Esses impostos e contribuições são de responsabilidade do contratante do serviço. Ou seja, você: cliente!
            </Paragraph>
            <Paragraph
              style={{
                fontSize: 14,
                lineHeight: 1.7,
                textAlign: 'left',
                color: 'rgba(0, 0, 0, 0.6)',
                marginBottom: 16
              }}
            >
              A HostDime Brasil é global, mas também é nacional. Por isso, nossos serviços são cobrados em real e com emissão de nota fiscal, com todos os impostos e contribuições inclusos em nosso preço final. Escolha uma parceria que te tire a dor de cabeça! <Link to='/contatos'>Entre em contato</Link> e conheça nossos serviços e soluções personalizadas.
            </Paragraph>
          </ReactTextCollapse>
        </Column>
      </Div>
    </Div>
  )
}

class MelhorDataCenter extends Component {
  render () {
    return (
      <div>
        <Awards
          licenceName={`Você paga o seu Data Center em Dólar? Além de ter que acompanhar a variação da moeda, você provavelmente não sabe que também tem que pagar 45% de impostos todo mês.`}
        />
      </div>
    )
  }
}

export default MelhorDataCenter