import React, { Fragment } from 'react';
import 'rc-collapse/assets/index.css';
import Collapse, { Panel } from 'rc-collapse'
import Column from '../components/Column'
import Div from '../components/Div'
import {
  CertificateContentItem,
  IsoItem
} from '../components/styles/Clipagem.styles'
import IcoLock from '@material-ui/icons/Lock'
import IcoAccountBalance from '@material-ui/icons/AccountBalance'
import IcoAssignmentInd from '@material-ui/icons/VerifiedUser'
import IcoAssignment from '@material-ui/icons/Assignment'
import IcoCloudDone from '@material-ui/icons/CloudDone'
import IcoStars from '@material-ui/icons/Stars'
import Iso27001 from '../images/certificates/ISO-27001.png'
import Iso20000 from '../images/certificates/ISO-20000.png'
import Iso27701 from '../images/certificates/ISO-27701.png'
import Iso27017 from '../images/certificates/ISO-27017.png'
import Iso27018 from '../images/certificates/ISO-27018.png'
import Iso9001 from '../images/certificates/ISO-9001.png'

const App = ({
  date
}) => (
  <Fragment>
    <Column
      mb={[16]}
      px={[0]}
      width={['100%']}
    >
      <Div
        maxWidth={['100%']}
      >
        <Collapse accordion expandIcon={IcoLock}>
          <Panel 
            header={
              <div> 
                <IcoLock />
                ISO 27001<span>:2013</span>
              </div>
             } 
            css={IsoItem} 
            showArrow={false}
          >
            <div css={CertificateContentItem}>
              <img src={Iso27001} style={{ width: 120, marginBottom: 40 }} />
              <p>A ISO 27001 certificou que a HostDime Brasil possui um modelo adequado de implementação, operação, monitoramento, revisão e gestão para Sistemas de Gestão de Segurança da Informação. É essencial para comprovar que o seu Data Center cumpre além dos altos padrões de infraestrutura.</p>
            </div>
          </Panel>
          <Panel 
            header={
              <div> 
                <IcoAccountBalance />
                ISO 20000<span>:2018</span>
              </div>
             } 
            css={IsoItem} 
            showArrow={false}
          >
            <div css={CertificateContentItem}>
              <img src={Iso20000} style={{ width: 120, marginBottom: 40 }} />
              <p>Com a certificação ISO 20000, a HostDime Brasil comprova que cumpre com o mais alto padrão de soluções e parceiros contratados, para atender às melhores práticas de manutenção, melhoria e inovação dos serviços prestados para você.</p>
            </div>
          </Panel>
          <Panel 
            header={
              <div> 
                <IcoAssignmentInd />
                ISO 27701<span>:2019</span>
              </div>
             } 
            css={IsoItem} 
            showArrow={false}
          >
            <div css={CertificateContentItem}>
              <img src={Iso27701} style={{ width: 120, marginBottom: 40 }} />
              <p>Somos a primeira empresa brasileira a conquistar essa certificação, que comprova nosso alto padrão de Segurança da Informação. A ISO 27701 garante a melhoria contínua na gestão da privacidade dos dados pessoais, por isso, também age em conformidade com a LGPD (Lei Geral da Proteção de Dados do Brasil) e GDPR (General Data Protection Regulation da Europa).</p>
            </div>
          </Panel>
          <Panel 
            header={
              <div> 
                <IcoAssignment />
                ISO 27017<span>:2015</span>
              </div>
             } 
            css={IsoItem} 
            showArrow={false}
          >
            <div css={CertificateContentItem}>
              <img src={Iso27017} style={{ width: 120, marginBottom: 40 }} />
              <p>Com a norma ISO 27017,  a HostDime Brasil é certificada como uma empresa que promove um rigoroso controle de segurança para Cloud Computing, reduzindo os riscos de problemas de segurança de informação para ambientes de nuvem aos níveis mais baixos.</p>
            </div>
          </Panel>
          <Panel 
            header={
              <div> 
                <IcoCloudDone />
                ISO 27018<span>:2019</span>
              </div>
             } 
            css={IsoItem} 
            showArrow={false}
          >
            <div css={CertificateContentItem}>
              <img src={Iso27018} style={{ width: 120, marginBottom: 40 }} />
              <p>Somos certificados no primeiro padrão internacional que estabelece um conjunto de boas práticas e controles de privacidade dos dados pessoais, voltado especificamente para serviços de computação em Nuvem Pública (Cloud Services Provider).</p>
            </div>
          </Panel>
          <Panel 
            header={
              <div> 
                <IcoStars />
                ISO 9001<span>:2015</span>
              </div>
             } 
            css={IsoItem} 
            showArrow={false}
          >
            <div css={CertificateContentItem}>
              <img src={Iso9001} style={{ width: 120, marginBottom: 40 }} />
              <p>Conquistamos a ISO 9001, que certifica que a empresa dispõe de um sistema de alto padrão na gestão da qualidade focado na satisfação total dos clientes HostDime Brasil, que contam com uma entrega de produtos e serviços de forma consistente.</p>
            </div>
          </Panel>
        </Collapse>
      </Div>
    </Column>
  </Fragment>
)

export default App